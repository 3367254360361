import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)"
        >
          <path d="M28128 18363 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z" />
          <path
            d="M28435 18360 c105 -4 231 -7 282 -6 68 1 89 -2 82 -11 -7 -7 -6 -14
2 -19 7 -4 9 -3 6 3 -4 7 9 10 36 9 38 -1 43 -4 39 -21 -2 -11 5 -28 15 -38
18 -16 19 -15 19 8 0 13 -3 22 -7 19 -4 -2 -10 0 -14 6 -3 5 0 10 8 10 22 0
44 -33 42 -63 -1 -15 2 -30 7 -33 4 -3 8 0 8 5 0 6 5 11 11 11 8 0 7 -5 -1
-15 -7 -9 -15 -13 -18 -10 -3 3 -12 -1 -21 -8 -14 -11 -14 -10 -3 5 12 15 11
17 -7 13 -11 -2 -23 1 -27 6 -3 5 -2 9 3 8 22 -4 44 2 39 10 -7 10 -25 9 -43
-2 -9 -6 -11 -34 -3 -44 2 -2 3 -6 4 -10 1 -5 5 -14 9 -20 8 -13 2 -17 -17
-14 -5 1 -4 -4 3 -13 9 -10 9 -16 1 -21 -8 -5 -6 -13 6 -26 16 -18 16 -19 -1
-19 -13 0 -15 -3 -6 -13 7 -10 7 -17 0 -24 -8 -8 -7 -13 1 -18 8 -5 6 -11 -7
-22 -17 -13 -16 -13 4 -4 12 6 24 7 27 2 8 -12 -19 -33 -30 -23 -4 4 -2 -1 5
-10 7 -10 10 -18 5 -18 -4 0 3 -11 16 -25 l23 -25 -24 0 c-14 0 -31 6 -38 13
-10 10 -11 8 -5 -7 5 -13 4 -17 -4 -12 -7 4 -12 2 -12 -5 0 -9 -3 -9 -14 1
-11 8 -20 9 -33 1 -19 -11 -22 -10 40 -15 6 -1 15 -5 19 -11 4 -6 8 -2 8 8 0
13 8 17 36 17 28 0 35 3 31 15 -4 8 -2 17 3 20 6 4 10 -1 10 -9 0 -9 7 -17 15
-17 8 -1 21 -2 28 -3 7 -1 15 16 18 39 l6 40 2 -37 c0 -20 4 -38 9 -41 4 -2 2
-2 -5 -1 -6 2 -17 -4 -23 -12 -10 -12 -7 -15 19 -20 34 -7 47 -19 32 -28 -5
-4 -12 -2 -16 4 -9 15 -36 12 -57 -7 -14 -12 -19 -13 -16 -4 2 8 -10 17 -29
23 -18 5 -33 13 -33 18 0 6 -4 10 -10 10 -19 0 2 -28 30 -40 15 -6 38 -22 51
-36 26 -28 35 -30 43 -8 4 11 2 15 -8 11 -15 -6 -16 17 -1 30 4 4 5 2 1 -4 -4
-7 -3 -13 3 -13 5 0 12 6 15 13 3 6 4 3 3 -8 -1 -12 7 -24 21 -30 12 -5 22
-13 22 -18 0 -13 -35 -3 -41 12 -5 12 -7 12 -12 -2 -4 -10 7 -32 28 -59 19
-23 40 -64 46 -90 7 -26 15 -45 19 -43 3 2 5 -45 4 -103 -1 -84 -5 -119 -22
-162 -26 -65 -25 -61 -7 -54 8 4 12 10 9 15 -3 5 3 9 13 9 16 0 16 -1 2 -16
-9 -8 -25 -17 -35 -18 -10 -2 -18 -11 -18 -20 1 -8 -2 -14 -6 -11 -9 6 -82
-69 -75 -77 4 -3 0 -6 -7 -5 -27 2 -148 -65 -147 -81 0 -11 2 -12 6 -4 2 6 11
12 19 12 8 0 14 5 14 10 0 5 3 9 8 8 4 0 15 2 25 5 25 10 41 14 51 15 5 0 12
5 15 11 4 5 22 13 40 16 28 5 32 3 23 -8 -9 -10 -9 -14 1 -14 7 0 13 10 13 22
0 13 -6 21 -17 21 -10 0 -15 -5 -12 -10 4 -5 1 -6 -6 -2 -13 9 -6 21 12 21 7
1 11 6 10 13 -2 7 5 12 15 12 16 0 19 -7 17 -40 -1 -30 2 -40 13 -40 9 0 12
-3 8 -7 -4 -5 -13 -8 -19 -8 -6 0 -12 -12 -12 -26 -1 -14 -5 -31 -9 -37 -4 -8
-2 -10 8 -6 10 4 14 0 12 -13 -2 -10 1 -23 6 -30 6 -7 2 -16 -13 -27 -17 -12
-20 -18 -11 -29 7 -8 8 -17 4 -21 -4 -4 0 -6 8 -4 8 2 15 -3 16 -9 1 -7 2 -16
3 -19 0 -4 7 -1 14 5 10 8 19 7 33 -5 16 -13 16 -15 2 -10 -10 3 -20 1 -23 -5
-4 -5 -10 -7 -15 -4 -5 3 -11 1 -15 -5 -4 -6 -11 -8 -16 -5 -5 4 -9 -1 -9 -9
0 -9 5 -15 12 -13 15 3 18 -23 5 -40 -5 -7 -6 -13 -2 -13 4 0 2 -7 -5 -15 -7
-8 -10 -16 -6 -16 10 -2 25 -6 34 -9 4 -1 11 -2 15 -1 5 1 5 -4 1 -11 -6 -9
-3 -9 9 1 9 7 14 18 10 23 -3 6 0 9 8 6 8 -3 16 -13 17 -23 3 -10 0 -15 -7
-11 -6 4 -11 2 -11 -4 0 -6 -4 -9 -9 -5 -5 3 -12 1 -16 -5 -13 -20 22 -20 68
0 29 13 45 26 41 32 -4 7 -2 8 5 4 15 -10 83 30 159 94 114 95 209 251 253
415 33 121 31 343 -4 478 -69 262 -249 463 -503 561 -164 63 -205 69 -554 71
-273 2 -290 2 -125 -5z m422 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m238 -64 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m-39 -22 c-9 -5 -22 -7 -29 -4 -7 2 -4 5 6 5 9 1 17 6 17 12 0 5 5
7 11 3 8 -5 6 -10 -5 -16z m89 -6 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3
15 -9 12 -12z m-231 -43 c-2 -4 0 -10 5 -13 5 -4 12 -2 15 3 8 13 46 1 46 -14
0 -7 -5 -15 -11 -17 -14 -5 -18 -56 -6 -82 7 -14 16 -17 39 -12 26 5 29 4 18
-9 -13 -16 -42 -20 -54 -7 -4 4 -12 8 -17 8 -19 3 -20 4 -12 17 4 6 8 26 8 44
1 24 -2 30 -10 23 -7 -5 -16 -6 -20 -2 -5 4 -4 0 1 -8 5 -8 9 -33 9 -55 1 -27
5 -39 14 -37 7 1 10 -2 6 -8 -3 -5 0 -7 8 -4 16 6 33 -13 22 -24 -3 -3 -10 -1
-14 6 -6 10 -11 10 -21 -3 -12 -14 -15 -14 -31 1 -14 13 -16 20 -8 31 6 7 8
13 4 13 -4 0 -2 6 4 14 9 10 8 16 -4 26 -12 10 -13 16 -4 26 6 8 8 14 4 14 -5
0 0 9 10 20 10 11 21 17 26 15 4 -3 10 -1 13 4 4 5 1 13 -5 17 -8 5 -9 3 -4
-7 5 -8 5 -11 0 -7 -4 4 -14 3 -22 -3 -7 -6 -14 -9 -14 -5 -1 3 -2 9 -3 14 -1
4 -4 12 -8 18 -4 6 -1 11 7 11 8 0 12 -4 9 -8z m76 -109 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m185 -71 c-3 -30 -25 -67
-25 -42 0 7 5 15 10 18 6 4 8 11 4 16 -4 7 -10 6 -18 0 -9 -7 -12 -4 -12 14 0
13 3 22 7 19 4 -2 10 0 14 6 11 18 23 1 20 -31z m-202 -37 c3 7 6 -6 6 -29 1
-41 -10 -55 -24 -33 -5 8 -11 8 -20 0 -9 -7 -15 -7 -23 2 -18 22 -18 26 8 53
22 23 26 24 36 10 9 -12 13 -13 17 -3z m24 -54 c-2 -13 -4 -3 -4 22 0 25 2 35
4 23 2 -13 2 -33 0 -45z m96 -38 c-3 -11 -10 -20 -15 -20 -5 0 -6 -4 -3 -10 3
-5 1 -10 -4 -10 -14 0 -14 13 1 28 9 9 9 12 -2 12 -13 0 -13 2 0 10 23 15 29
12 23 -10z m57 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m0 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m-30 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-25 -30 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10
9 10 6 0 13 -4 16 -10z m6 -48 c-6 -10 -9 -27 -7 -38 2 -10 1 -13 -1 -6 -3 7
-9 10 -14 7 -12 -8 -11 -1 3 30 6 14 15 25 20 25 5 0 4 -8 -1 -18z m16 -49
c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-2 -44 c-4 -5 11 -10
32 -10 l38 -2 -35 -6 c-42 -8 -40 -9 -45 18 -2 12 0 19 6 16 5 -4 7 -11 4 -16z
m53 -46 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m-8 -142 c0
-22 6 -42 13 -45 6 -3 3 -5 -8 -3 -16 2 -21 11 -23 45 -2 25 1 42 7 42 6 0 11
-18 11 -39z m40 -1 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m-10 -80 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m-5 -30 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10
10 10 2 0 7 -4 10 -10z m-17 -27 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0
27 -2 18 -5z m-67 -102 c-8 -5 -5 -13 9 -26 24 -22 17 -37 -16 -31 -21 4 -24
3 -14 -10 15 -17 24 -19 14 -2 -4 6 -3 8 4 5 11 -8 11 -25 -2 -45 -6 -11 0
-12 29 -6 27 5 36 3 31 -4 -4 -6 -13 -9 -21 -5 -10 3 -15 -1 -16 -13 -5 -80
-3 -101 11 -118 14 -17 13 -18 -5 -15 -11 3 -20 9 -20 16 0 6 -3 15 -7 19 -4
3 -5 -3 -3 -15 3 -15 0 -19 -8 -15 -9 5 -9 3 0 -6 8 -9 9 -17 3 -25 -7 -9 -6
-16 4 -25 13 -10 15 -8 11 9 -5 25 11 37 19 15 6 -16 -12 -49 -22 -39 -3 3
-12 -1 -21 -7 -9 -7 -13 -8 -8 -3 4 4 -2 18 -13 30 -20 22 -20 22 0 40 11 10
16 23 12 29 -3 6 -7 19 -8 29 -1 9 -3 18 -4 20 -6 6 -6 56 0 52 4 -3 13 1 19
9 8 10 8 16 -2 25 -27 22 -9 85 20 74 15 -6 16 -5 3 9 -12 12 -18 13 -29 4
-12 -9 -13 -8 -6 4 16 26 30 38 39 33 4 -3 3 -9 -3 -12z m114 -31 c18 -8 17
-9 -7 -9 -21 -1 -28 -6 -28 -20 0 -19 -18 -31 -46 -31 -8 0 -14 5 -14 10 0 6
12 10 26 10 22 0 25 2 14 15 -10 12 -10 15 4 15 9 0 16 5 16 10 0 12 8 12 35
0z m15 -30 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15
-10z m-15 -59 c-11 -1 -23 -8 -27 -14 -4 -6 -10 -8 -15 -4 -4 5 -3 11 3 15 6
4 13 15 17 25 6 17 7 17 24 0 18 -17 18 -18 -2 -22z m-65 -55 c0 -8 -4 -18
-10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m40 4 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m24 -51 c-8
-14 -24 -10 -24 6 0 9 6 12 15 9 8 -4 12 -10 9 -15z m-37 -45 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m100 -10 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-100 -90 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m73 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z m-129 -15 c0 -6 5 -15 10 -22 7 -7 6 -14 -2 -19 -9 -5 -7 -11
7 -22 15 -12 15 -13 2 -9 -17 6 -43 -15 -32 -26 5 -5 -21 -2 -36 3 -3 1 -9 2
-13 1 -5 -1 -5 4 0 11 4 7 8 16 9 21 1 4 2 8 4 10 7 9 7 38 -1 43 -5 3 -7 9
-5 13 7 11 56 7 57 -4z m174 5 c-3 -5 -1 -10 5 -10 6 0 8 -5 4 -12 -6 -9 -9
-9 -11 -1 -3 7 -12 9 -21 6 -13 -4 -12 -2 3 10 23 19 29 21 20 7z m-18 -46
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-37 -25 c0 -4 -8 -5
-17 -2 -16 6 -17 5 -3 -13 14 -18 14 -18 -2 -5 -10 7 -18 10 -18 6 0 -5 -3 -6
-6 -2 -9 9 19 35 33 30 7 -3 13 -9 13 -14z"
          />
          <path d="M28905 18121 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
          <path
            d="M28906 18042 c-3 -6 1 -15 8 -21 8 -7 17 -8 20 -3 3 6 -1 15 -8 21
-8 7 -17 8 -20 3z"
          />
          <path
            d="M29151 18047 c-8 -10 -7 -14 2 -14 8 0 14 6 14 14 0 7 -1 13 -2 13
-2 0 -8 -6 -14 -13z"
          />
          <path
            d="M28919 17977 c0 -1 -2 -10 -4 -19 -4 -24 10 -31 25 -13 7 8 16 12 21
9 5 -3 9 0 9 5 0 6 -3 11 -7 12 -5 0 -16 2 -25 4 -10 2 -18 3 -19 2z m31 -12
c0 -2 -6 -8 -13 -14 -10 -8 -14 -7 -14 2 0 8 6 14 14 14 7 0 13 -1 13 -2z"
          />
          <path
            d="M29074 17153 c2 -10 6 -17 10 -16 15 3 16 11 2 22 -12 10 -14 9 -12
-6z"
          />
          <path
            d="M29078 17093 c-13 -16 -16 -33 -8 -48 1 -3 3 -14 4 -24 0 -11 5 -23
9 -28 5 -4 7 -1 5 7 -1 8 2 14 8 13 10 -2 13 81 3 91 -3 4 -12 -2 -21 -11z
m17 -27 c0 -19 -5 -39 -9 -43 -5 -4 -6 -3 -2 4 3 6 2 14 -3 17 -10 7 -9 25 2
44 12 18 13 15 12 -22z"
          />
          <path
            d="M29069 16968 c-7 -24 -4 -40 9 -50 13 -11 14 -11 7 2 -5 8 -8 23 -7
33 3 13 -5 26 -9 15z"
          />
          <path
            d="M29078 16837 c7 -10 9 -32 2 -39 -6 -9 -7 -39 0 -43 17 -10 41 37 30
60 -1 3 -3 10 -4 17 0 6 -9 12 -19 14 -12 2 -15 -1 -9 -9z m26 -46 c3 -5 -1
-14 -8 -20 -12 -10 -14 -9 -11 6 2 10 4 19 4 21 2 5 10 2 15 -7z"
          />
          <path
            d="M28836 18306 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6
-12z"
          />
          <path
            d="M28685 18300 c-4 -6 -10 -8 -15 -5 -15 9 -21 -12 -6 -24 10 -8 17 -6
30 8 10 11 13 21 7 25 -5 4 -12 2 -16 -4z m15 -4 c0 -2 -6 -9 -14 -15 -11 -9
-16 -9 -22 0 -4 7 -3 9 4 5 6 -3 13 -2 17 4 6 10 15 14 15 6z"
          />
          <path
            d="M28924 18286 c1 -8 5 -18 8 -22 4 -3 5 1 4 10 -1 8 -5 18 -8 22 -4 3
-5 -1 -4 -10z"
          />
          <path
            d="M28800 18240 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z"
          />
          <path d="M28848 18093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M28220 17910 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z"
          />
          <path
            d="M28370 17909 c-30 -3 36 -6 147 -7 111 -1 204 1 206 6 6 8 -273 10
-353 1z"
          />
          <path d="M28188 17903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M28263 17903 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
          <path
            d="M28735 17900 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5
0 -13 -4 -16 -10z"
          />
          <path
            d="M28765 17080 c-16 -4 -147 -9 -290 -10 -143 -1 -253 -4 -245 -6 29
-7 558 -9 554 -2 -3 4 4 11 13 17 20 12 11 12 -32 1z"
          />
          <path d="M28188 17063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M29036 16885 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2
0 -6 -7 -10 -15z"
          />
          <path d="M28968 16873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path
            d="M29054 16715 c-18 -14 -18 -15 3 -12 27 4 38 13 25 21 -5 3 -18 -1
-28 -9z"
          />
          <path d="M28922 16695 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M28940 16716 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
          <path
            d="M29008 16687 c8 -10 6 -16 -10 -26 -17 -11 -22 -10 -29 1 -6 8 -9 9
-9 1 0 -6 -8 -10 -17 -8 -10 1 -29 -3 -43 -10 -14 -7 -31 -10 -39 -7 -8 2 -11
0 -7 -6 7 -12 30 -10 -309 -17 -148 -4 -274 -8 -280 -10 -17 -7 548 1 575 8
14 3 54 13 90 21 156 35 174 41 164 57 -3 6 -12 5 -22 -3 -13 -11 -14 -11 -7
2 5 9 0 8 -13 -4 -19 -17 -22 -17 -22 -3 0 10 -7 17 -16 17 -12 0 -14 -3 -6
-13z"
          />
          <path d="M28210 16610 c-12 -7 -10 -9 7 -7 12 0 19 5 17 9 -6 10 -6 10 -24 -2z" />
          <path d="M28058 15813 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
